import * as React from "react";
import '/src/styles/global-styles.css';
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import { Helmet } from 'react-helmet';
import Layout from "../../components/layout";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import commonMessageValues from '../../templates/formatjs-message-values';

const TrainingForBanksAtAPS = () => {
  const m = useIntl().messages;
  const intl = useIntl();
  const images = useStaticQuery(graphql`{
    training_for_banks: file(relativePath: {eq: "training_for_banks.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    training_for_banks_1: file(relativePath: {eq: "training_for_banks_1.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    training_for_banks_2: file(relativePath: {eq: "training_for_banks_2.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    training_for_banks_3: file(relativePath: {eq: "training_for_banks_3.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 70, layout: FULL_WIDTH)
      }
    }
    formatter: allMarkdownRemark(sort: {fields: frontmatter___date}) {
      nodes {
        frontmatter {
          title
          slug
          date
        }
        excerpt
      }
    }
  }
  `);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{m.training_for_banks}</title>
      </Helmet>
      <div className="proper-height">
        <GatsbyImage image={images.training_for_banks.childImageSharp.gatsbyImageData}/>
      </div>
      <div className={"ui fluid segment vertical container " + (intl.locale === "en" ? "text-ltr" : "text-rtl")}>
        <div className="ui eight wide column" style={{marginTop: '30px'}}>
          <div className="column">
            <FormattedMessage id="training_for_banks_message" values={commonMessageValues}/>
          </div>
        </div>
        <br/>
        <br/>
        <div className="ui eight wide column" style={{marginTop: '30px'}}>
          <div className="column">
            <div className="proper-height">
              <GatsbyImage image={images.training_for_banks_1.childImageSharp.gatsbyImageData}/>
              <br/>
              <GatsbyImage image={images.training_for_banks_2.childImageSharp.gatsbyImageData}/>
              <br/>
              <GatsbyImage image={images.training_for_banks_3.childImageSharp.gatsbyImageData}/>
              <br/>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TrainingForBanksAtAPS;